var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('horizontal-navbar',{attrs:{"arrow-visible":true},on:{"go-back":_vm.goBack}}),_c('validation-observer',{ref:"refFormObserver"},[_c('b-form',{staticClass:"mt-2 mb-2"},[_c('b-row',[_c('b-col',{staticClass:"mt-50 mb-2 d-flex justify-content-between align-items-center",attrs:{"cols":"12"}},[_c('h3',[_vm._v(_vm._s(_vm.templatePageId ? _vm.pageTitle : _vm.$t('global.add')))]),_c('div',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"disabled":!_vm.isValid,"variant":"primary"},on:{"click":_vm.addUpdate}},[_vm._v(" "+_vm._s(_vm.$t('global.save'))+" ")]),(_vm.templatePageId)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"danger"},on:{"click":_vm.confirmText}},[_vm._v(" "+_vm._s(_vm.$t('global.delete'))+" ")]):_vm._e()],1)]),_c('b-col',{attrs:{"md":"12"}},[_c('b-card',[_c('div',{staticClass:"d-flex mb-2 align-items-center"},[_c('b-col',{staticClass:"p-0 mb-md-0",attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label-for":"userType"}},[_c('label',[_vm._v(_vm._s(_vm.$t('templatePages.publishingDate')))]),_c('v-select',{staticClass:"w-100",attrs:{"id":"published","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.publishPageOptions,"label":"title"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var title = ref.title;
return [_c('span',[_vm._v(" "+_vm._s(title))])]}}]),model:{value:(_vm.isPublished),callback:function ($$v) {_vm.isPublished=$$v},expression:"isPublished"}}),(_vm.wasPublished)?_c('label',[_vm._v(_vm._s(_vm.$t('global.date'))+": "+_vm._s(new Date(_vm.publishDate).toLocaleString()))]):_vm._e()],1)],1)],1),_c('div',{staticClass:"d-flex mb-2 align-items-center"},[_c('b-col',{staticClass:"p-0 mb-md-0",attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label-for":"userType"}},[_c('label',[_vm._v(_vm._s(_vm.$t('templatePages.typeOfPage')))]),_c('v-select',{staticClass:"w-100",attrs:{"id":"existed_page","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.typeOptions,"label":"title"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var title = ref.title;
return [_c('span',[_vm._v(" "+_vm._s(title))])]}}]),model:{value:(_vm.pageType),callback:function ($$v) {_vm.pageType=$$v},expression:"pageType"}})],1)],1),_c('b-col',{staticClass:"mb-md-0",attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label-for":"userType"}},[_c('label',[_vm._v(_vm._s(_vm.$t('templatePages.categoryOfPage')))]),_c('v-select',{staticClass:"w-100",attrs:{"id":"category","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.categoriesList,"label":"title"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var title = ref.title;
return [_c('span',[_vm._v(" "+_vm._s(title))])]}}]),model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}})],1)],1),(_vm.page_exists)?_c('b-col',{staticClass:"mr-1 p-0 mb-md-0",attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":_vm.$t('templatePages.localLink'),"label-for":"local_path"}},[_c('b-form-input',{attrs:{"id":"local_path"},model:{value:(_vm.localPath),callback:function ($$v) {_vm.localPath=$$v},expression:"localPath"}})],1)],1):_vm._e()],1),_c('b-tabs',[_c('b-tab',{attrs:{"title":_vm.$t('global.geo'),"active":_vm.$i18n.locale === 'ka'}},[_c('p',[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t('templatePages.titleAndSlagMandatory')))])]),_c('div',{staticClass:"m-0 d-flex justify-content-between"},[_c('b-col',{staticClass:"p-0",attrs:{"md":_vm.page_exists ? '6' : '4'}},[_c('validation-provider',{attrs:{"name":"Commercial Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var dirty = ref.dirty;
return [_c('b-form-group',{staticClass:"mb-2",attrs:{"label":_vm.$t('global.pageTitle'),"label-for":"faq-title_ka"}},[_c('b-form-input',{attrs:{"id":"faq-title_ka","state":dirty ? (errors[0] ? false : (valid ? true : null)) : null},on:{"input":_vm.debounceSearch_ka},model:{value:(_vm.pageInfo.title_ka),callback:function ($$v) {_vm.$set(_vm.pageInfo, "title_ka", $$v)},expression:"pageInfo.title_ka"}})],1)]}}])})],1),_c('b-col',{attrs:{"md":_vm.page_exists ? '6' : '4'}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":_vm.$t('global.sharingTitle'),"label-for":"faq-title_ka"}},[_c('b-form-input',{attrs:{"id":"share-title_ka"},model:{value:(_vm.pageInfo.share_title_ka),callback:function ($$v) {_vm.$set(_vm.pageInfo, "share_title_ka", $$v)},expression:"pageInfo.share_title_ka"}})],1)],1),(!_vm.page_exists)?_c('b-col',{staticClass:"p-0",attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"Commercial Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var dirty = ref.dirty;
return [_c('b-form-group',{staticClass:"mb-2",attrs:{"label":_vm.$t('global.slag'),"label-for":"slug_ka"}},[_c('b-form-input',{attrs:{"id":"slug_ka","state":dirty ? (errors[0] ? false : (valid ? true : null)) : null},model:{value:(_vm.pageInfo.slug_ka),callback:function ($$v) {_vm.$set(_vm.pageInfo, "slug_ka", $$v)},expression:"pageInfo.slug_ka"}})],1)]}}],null,false,1031662672)})],1):_vm._e()],1),_c('b-form-group',{staticClass:"mb-2",attrs:{"label":_vm.$t('global.sharingDescription'),"label-for":"share-description_ka"}},[_c('b-form-textarea',{attrs:{"id":"share-description_ka"},model:{value:(_vm.pageInfo.share_description_ka),callback:function ($$v) {_vm.$set(_vm.pageInfo, "share_description_ka", $$v)},expression:"pageInfo.share_description_ka"}})],1),(!_vm.page_exists && _vm.pageInfo.description_ka !== null)?_c('text-editor',{attrs:{"id":"page_description_ka","editor":_vm.pageInfo.description_ka},on:{"update":_vm.updateDescriptionValue_ka}}):_vm._e()],1),_c('b-tab',{attrs:{"title":_vm.$t('global.eng'),"active":_vm.$i18n.locale === 'en'}},[_c('p',[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t('templatePages.titleAndSlagMandatory')))])]),_c('div',{staticClass:"m-0 d-flex justify-content-between"},[_c('b-col',{staticClass:"p-0",attrs:{"md":_vm.page_exists ? '6' : '4'}},[_c('validation-provider',{attrs:{"name":"Commercial Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var dirty = ref.dirty;
return [_c('b-form-group',{staticClass:"mb-2",attrs:{"label":_vm.$t('global.pageTitle'),"label-for":"faq-title_en"}},[_c('b-form-input',{attrs:{"id":"faq-title_en","state":dirty ? (errors[0] ? false : (valid ? true : null)) : null},on:{"input":_vm.debounceSearch_en},model:{value:(_vm.pageInfo.title_en),callback:function ($$v) {_vm.$set(_vm.pageInfo, "title_en", $$v)},expression:"pageInfo.title_en"}})],1)]}}])})],1),_c('b-col',{attrs:{"md":_vm.page_exists ? '6' : '4'}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":_vm.$t('global.sharingTitle'),"label-for":"faq-title_en"}},[_c('b-form-input',{attrs:{"id":"share-title_en"},model:{value:(_vm.pageInfo.share_title_en),callback:function ($$v) {_vm.$set(_vm.pageInfo, "share_title_en", $$v)},expression:"pageInfo.share_title_en"}})],1)],1),(!_vm.page_exists)?_c('b-col',{staticClass:"p-0",attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"Commercial Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var dirty = ref.dirty;
return [_c('b-form-group',{staticClass:"mb-2",attrs:{"label":_vm.$t('global.slag'),"label-for":"slug_en"}},[_c('b-form-input',{attrs:{"id":"slug_en","state":dirty ? (errors[0] ? false : (valid ? true : null)) : null},model:{value:(_vm.pageInfo.slug_en),callback:function ($$v) {_vm.$set(_vm.pageInfo, "slug_en", $$v)},expression:"pageInfo.slug_en"}})],1)]}}],null,false,3459471633)})],1):_vm._e()],1),_c('b-form-group',{staticClass:"mb-2",attrs:{"label":_vm.$t('global.sharingDescription'),"label-for":"share-description_en"}},[_c('b-form-textarea',{attrs:{"id":"share-description_en"},model:{value:(_vm.pageInfo.share_description_en),callback:function ($$v) {_vm.$set(_vm.pageInfo, "share_description_en", $$v)},expression:"pageInfo.share_description_en"}})],1),(!_vm.page_exists && _vm.pageInfo.description_en !== null)?_c('text-editor',{attrs:{"id":"page_description_en","editor":_vm.pageInfo.description_en},on:{"update":_vm.updateDescriptionValue_en}}):_vm._e()],1)],1)],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('b-card',[_c('div',[_c('h4',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('global.sharingImage'))+" ")]),(_vm.image.name)?[_c('p',{staticClass:"mt-1 mb-1"},[_vm._v(" "+_vm._s(_vm.$t('uploadImage.uploadedPicture'))+" ")]),_c('div',{staticClass:"image-wrapper"},[_c('div',{},[_c('img',{staticClass:"mw-100",attrs:{"src":_vm.staticEnvValue + "/" + _vm.image.name}})])])]:_vm._e(),_c('image-upload',{attrs:{"id":"template_page_image","store":"app-content","url":"cropImage","module":"contentStoreModule","on-crop":_vm.addImage,"x":1200,"y":628}})],2)])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }